import React from 'react'
import OrderContainer from '../containers/OrderContainer';

export default function CheckoutPage() {
    return (
        <div>
            <OrderContainer />
        </div>
    )
}
